/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTabName } from 'store/slices/menu/menu.selector';
import userManageSelect from 'store/slices/userManage/userManage.selector';
import {
  addUserToGroup,
  deleteUserToGroup,
  fetchSelectedUser,
  setGroupAdmin,
  updateUserAttribute,
  updateUserToPositions,
} from 'store/slices/userManage/userManageThunk';
import { isLoading, positionOptions } from 'store/slices/positions/positions.selector';
import {
  selectAccessPermission,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import { changeTab } from 'store/slices/menu/menuSlice';
import { TabName } from 'store/slices/menu/menuState';
import { updateUserPermission } from 'store/slices/userManage/userManageSlice';
import UserPermissionView from './UserPermissionView.view';
import { ISiteStatusList, SiteAction, UserPermissionViewProps } from './UserPermissionView.props';
import { IAccountPositions, ICurrentGroups } from 'interfaces/userinfo.interface';
import { ISelectedPositions } from 'interfaces/ISelectOptions';
import { IPositionsPayload } from 'interfaces/positions.interface';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import {
  AssignGroupAdminPayload,
  IAssignGroupPayload,
  IGroupList,
  IRevokeGroupPayload,
} from 'interfaces/groups.interface';
import { Attributes, PayloadAttribute } from 'interfaces/attribute.interface';
import usePositionsForm, { UserPositionsFormData } from './hook/usePositions';
import useGrantAccessForm, { defaultSelections } from './hook/useGrantAccess';
import useGroupForm, { UserGroupFormData } from 'hooks/useGroupForm';
import {
  AccessPermission,
  AssignGroupFormStep,
  ProgramCodes,
  ProgramTitles,
  SiteStatusCodes,
} from 'common/enum';
import {
  DefineUserAttributesData,
  findKeyIndAttribute,
  generateDataPermissions,
  SetNewAttributesData,
} from 'common/utils';
import {
  DMASelectOptions,
  LEMPGSelectOptions,
  MitigationSelectOptions,
  pageSteps,
  REPSelectOptions,
  SCEMDCountyIDs,
  StackableStep,
} from 'common/static';
import userManageSelector from '../../store/slices/userManage/userManage.selector';
import { findIndex } from 'lodash';
import { fetchPositionsByGroupID } from '../../store/slices/positions/positionsThunk';

const UserPermissionViewMain = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const userId = params?.id || 0;
  const groupId = params?.groupId || 0;
  const tabName = useAppSelector(selectTabName);
  const userRecord = useAppSelector(userManageSelect.selectedUser);
  const groupLists = useAppSelector(userManageSelector.selectGroupLists);
  const isProcessing = useAppSelector(userManageSelector.inProcess);
  const fetchingUsers = useAppSelector(userManageSelector.isLoading);
  const isPositionLoading = useAppSelector(isLoading);
  const positionsData = useAppSelector(positionOptions);
  const userPermission = useAppSelector(selectAccessPermission);
  const userActiveGroupID = useAppSelector(selectActiveGroupId);
  const currentUserAccount = useAppSelector(selectUserAccount);
  const accessPermission = useAppSelector(selectAccessPermission);
  const loggedInUserGroupInfo = (currentUserAccount && currentUserAccount?.currentGroups) || [];
  const isUserPendingApproval = userRecord && userRecord.status === 'Pending Approval';
  const location = useLocation();
  const { propsState } = location.state || {};

  const groupForm: UseFormReturn<UserGroupFormData> = useGroupForm();
  const positionsForm = usePositionsForm();
  const grantAccessForm = useGrantAccessForm();
  const [openGrantSiteAccess, setOpenGrantSiteAccess] = useState(false);
  const [openGrantSiteRequest, setOpenGrantSiteRequest] = useState(false);
  const [openDenySiteRequest, setOpenDenySiteRequest] = useState(false);
  const [openRevokeAccess, setOpenRevokeAccess] = useState(false);
  const [openRevokeGroup, setOpenRevokeGroup] = useState(false);
  const [groupPosition, setGroupPosition] = useState<IAccountPositions | null>(null);
  const [inProcessing, setInProcessing] = useState(false);
  const [openEditPositions, setOpenEditPositions] = useState(false);
  const [openAssignGroup, setOpenAssignGroup] = useState<boolean>(false);
  const [isRevokeGroupProcessing, setIsRevokeGroupProcessing] = useState(false);
  const [activeSites, setActiveSites] = useState<ISiteStatusList[]>([]);
  const [inActiveSites, setInActiveSites] = useState<ISiteStatusList[]>([]);
  const [siteRequests, setSiteRequests] = useState<ISiteStatusList[]>([]);
  const [messageModal, setMessageModal] = useState('');
  const [inProcessAttribute, setInProcessAttribute] = useState(false);
  const [hasNoPositions, setHasNoPositions] = useState<boolean>(false);
  const [hasNoPositionsGroupForm, setHasNoPositionsGroupForm] = useState<boolean>(true);
  const [isGroupAdmin, setIsGroupAdmin] = useState<boolean>(false);
  const [processingGroupAdmin, setProcessingGroupAdmin] = useState(false);
  const [activeGroupInfo, setActiveGroupInfo] = useState<ICurrentGroups | null>(null);
  const [activePermissionCodes, setActivePermissionCodes] = useState<{ [key: string]: string }[]>(
    []
  );
  const [filteredGroupLists, setFilteredGroupLists] = useState<IGroupList[]>(groupLists);
  const [step, setStep] = useState<AssignGroupFormStep>(AssignGroupFormStep.GROUP);
  const [stepHistory, setStepHistory] = useState<StackableStep[]>(pageSteps);

  const selectedGroupID = groupForm.watch('groupId');
  const currentUserGroupInfo = userRecord?.currentGroups || [];

  const fullName =
    (userRecord &&
      userRecord?.pvPersonGivenName &&
      userRecord?.pvPersonSurName &&
      `${userRecord?.pvPersonGivenName} ${userRecord?.pvPersonSurName}`) ||
    '';
  const userEmail = (userRecord && userRecord?.email) || '';

  const currentAttributes =
    userRecord &&
    Array.isArray(userRecord.attributes) &&
    userRecord.attributes.length > 0 &&
    userRecord.attributes;

  const singleGroupUser = useMemo(() => Boolean(propsState?.isSingleGroup), [propsState]);

  useEffect(() => {
    if (selectedGroupID) {
      dispatch(fetchPositionsByGroupID(selectedGroupID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupID]);

  useEffect(() => {
    if (positionsData && positionsData.length) {
      const tempOptions: ISelectedPositions[] = [];
      positionsData.map((p) => {
        const option: ISelectedPositions = {
          checked: false,
          value: p.pvPositionID,
          label: p.pvPositionName.trim(),
        };
        tempOptions.push(option);
      });
      if (tempOptions.length > 0) setHasNoPositionsGroupForm((prevState) => !prevState);
      groupForm.setValue('positions', tempOptions);
    } else {
      setHasNoPositionsGroupForm(() => true);
      groupForm.setValue('positions', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsData]);

  // *: User Form Positions List
  useEffect(() => {
    if (positionsData && positionsData.length) {
      const tempOptions: ISelectedPositions[] = [];

      if (groupPosition && Object.keys(groupPosition).length > 0) {
        const userPositions = groupPosition.positions;
        positionsData.map((p) => {
          const isSelected = userPositions.findIndex((u) => u.i === p.pvPositionID);
          if (isSelected > -1) {
            const option: ISelectedPositions = {
              checked: true,
              value: p.pvPositionID,
              label: p.pvPositionName.trim(),
            };
            tempOptions.push(option);
          } else {
            const option: ISelectedPositions = {
              checked: false,
              value: p.pvPositionID,
              label: p.pvPositionName.trim(),
            };
            tempOptions.push(option);
          }
        });
      } else {
        // *: List all unchecked positions
        positionsData.map((p) => {
          const option: ISelectedPositions = {
            checked: false,
            value: p.pvPositionID,
            label: p.pvPositionName.trim(),
          };
          tempOptions.push(option);
        });
      }
      const formData: UserPositionsFormData = {
        groupId: Number(groupId),
        userId: Number(userId),
        positions: tempOptions,
      };
      positionsForm.reset(formData);
    }
  }, [positionsData, groupPosition]);

  // *: User active positions
  useEffect(() => {
    if (userRecord && userRecord?.account2position && groupId) {
      if (userRecord.account2position.length === 0) setHasNoPositions(true);
      grantAccessForm.setValue('groupId', Number(groupId));
      grantAccessForm.setValue('userId', Number(userRecord.id));
      if (currentAttributes) grantAccessForm.setValue('attributes', currentAttributes);
      const filteredPosition =
        userRecord?.account2position &&
        userRecord?.account2position.find((p) => p.i === Number(groupId));
      if (filteredPosition && filteredPosition.positions.length) {
        setHasNoPositions(false);
        return setGroupPosition(filteredPosition);
      }
      if (userRecord && userRecord?.currentGroups && userRecord?.currentGroups.length) {
        const filteredGroup = userRecord?.currentGroups.find((g) => g.i === Number(groupId));
        const payloadPosition: IAccountPositions = {
          i: Number(groupId),
          n: filteredGroup?.n || '',
          a: filteredGroup?.a ? filteredGroup.a : 0,
          positions: [],
        };
        setGroupPosition(payloadPosition);
      }
    }
  }, [userRecord, groupId]);

  // *: User Request, Active, and In-Active Sites
  useEffect(() => {
    if (userRecord && userRecord.attributes) {
      setActivePermissionCodes([]); // *: Reset Active Permission Codes every refresh of user record
      if (Array.isArray(userRecord.attributes)) {
        const tempAttributes = DefineUserAttributesData(userRecord.attributes);
        const programs = Object.keys(ProgramCodes);
        const tempActive: {
          code: string;
          title: string;
          active: boolean;
          hasPermission: boolean;
        }[] = [];
        const tempInActive: { code: string; title: string; active: boolean }[] = [];
        const tempRequest: { code: string; title: string; active: boolean }[] = [];
        const notWithInSCEMDCounty = !SCEMDCountyIDs.includes(Number(groupId));
        // Add Active Sites
        for (const attribute of tempAttributes) {
          if (typeof attribute === 'object') {
            for (const appCode of programs) {
              const checkCode = (attribute as Attributes)[appCode];
              const isCodeExist = checkCode !== undefined;
              const indexOfC = Object.values(ProgramCodes).indexOf(
                appCode as unknown as ProgramCodes
              );
              const appTitle = Object.values(ProgramTitles)[indexOfC];
              if (isCodeExist) {
                const infoObj = checkCode;
                if (Array.isArray(infoObj)) {
                  const payload = {
                    code: appCode,
                    title: appTitle,
                    active: isCodeExist,
                    hasPermission: false,
                  };
                  if (infoObj.length === 1) {
                    //   Only have default Status
                    const accessApp = infoObj[0] as unknown as { S: SiteStatusCodes };
                    if (accessApp && accessApp.S === SiteStatusCodes.ACTIVE) {
                      const isExist = tempActive.findIndex((v) => v.code === appCode) > 0;
                      if (!isExist) tempActive.push(payload);
                    } else if (accessApp && accessApp.S === SiteStatusCodes.REQUEST) {
                      const isExist = tempRequest.findIndex((v) => v.code === appCode) > 0;
                      if (!isExist) tempRequest.push(payload);
                    } else {
                      const isExist = tempInActive.findIndex((v) => v.code === appCode) > 0;
                      if (!isExist) tempInActive.push(payload);
                    }
                  } else {
                    const idxOfActiveGroupApp = findKeyIndAttribute(infoObj, String(groupId));
                    if (idxOfActiveGroupApp > -1) {
                      const infoAccessAppByGroup = infoObj[idxOfActiveGroupApp];
                      if (typeof infoAccessAppByGroup === 'object') {
                        console.log('IS AN OBJECT');
                        const infoAccessByGroup =
                          Object.prototype.hasOwnProperty.call(
                            infoAccessAppByGroup,
                            String(groupId)
                          ) && infoAccessAppByGroup[String(groupId)];
                        if (infoAccessByGroup && Array.isArray(infoAccessByGroup)) {
                          const idxOfPermissionType = findKeyIndAttribute(infoAccessByGroup, 'P');
                          if (idxOfPermissionType > -1) {
                            const permissionInfo = infoAccessByGroup[idxOfPermissionType];
                            const permissionCode = permissionInfo.P;
                            if (permissionCode && Array.isArray(permissionCode)) {
                              const permissionInfo = { [appCode]: permissionCode[0] };
                              setActivePermissionCodes((prevState) => [
                                ...prevState,
                                permissionInfo,
                              ]);
                            }
                            const isPermissionDefaultGroup = permissionCode[0] === 'G';
                            // *: Do Not Show Permission Modal for Default Group
                            payload.hasPermission = !isPermissionDefaultGroup;
                          }
                          const idxOfSiteStatus = findKeyIndAttribute(infoAccessByGroup, 'S');
                          if (idxOfSiteStatus > -1) {
                            const siteStatusInfo = infoAccessByGroup[
                              idxOfSiteStatus
                            ] as unknown as {
                              S: SiteStatusCodes;
                            };
                            if (siteStatusInfo && siteStatusInfo.S === SiteStatusCodes.ACTIVE) {
                              const isExist = tempActive.findIndex((v) => v.code === appCode) > 0;
                              if (!isExist) tempActive.push(payload);
                            } else if (
                              siteStatusInfo &&
                              siteStatusInfo.S === SiteStatusCodes.REQUEST
                            ) {
                              const isExist = tempRequest.findIndex((v) => v.code === appCode) > 0;
                              if (!isExist) tempRequest.push(payload);
                            } else {
                              const isExist = tempInActive.findIndex((v) => v.code === appCode) > 0;
                              if (!isExist) tempInActive.push(payload);
                            }
                          }
                        }
                      }

                      if (Array.isArray(infoAccessAppByGroup) && infoAccessAppByGroup.length) {
                        console.log('IS AN ARRAY');
                      }
                    } else {
                      tempInActive.push(payload);
                    }
                  }
                }
              }
            }
          }
        }

        if (Number(groupId) !== 1) {
          // ?: Only SCEMD can have access to CALL DOWN
          const idxI = tempInActive.findIndex((a) => a.code === ProgramCodes.CDOWN);
          const idxR = tempRequest.findIndex((a) => a.code === ProgramCodes.CDOWN);
          if (idxI > -1) tempInActive.splice(idxI, 1);
          if (idxR > -1) tempRequest.splice(idxR, 1);
        }
        if (Number(groupId) > 48) {
          // ?: Restrict access to LEMPG / SUPPLEMENTAL / SERCLEPC for SCEMD and County Group Only
          const idxI = tempInActive.findIndex((a) => a.code === ProgramCodes.LEMPG);
          const idxR = tempRequest.findIndex((a) => a.code === ProgramCodes.LEMPG);
          if (idxI > -1) tempInActive.splice(idxI, 1);
          if (idxR > -1) tempRequest.splice(idxR, 1);
          const idxSI = tempInActive.findIndex((a) => a.code === ProgramCodes.SUPPTL);
          const idxSR = tempRequest.findIndex((a) => a.code === ProgramCodes.SUPPTL);
          if (idxSI > -1) tempInActive.splice(idxSI, 1);
          if (idxSR > -1) tempRequest.splice(idxSR, 1);
          const idxLI = tempInActive.findIndex((a) => a.code === ProgramCodes.SERCLEPC);
          const idxLR = tempRequest.findIndex((a) => a.code === ProgramCodes.SERCLEPC);
          if (idxLI > -1) tempInActive.splice(idxLI, 1);
          if (idxLR > -1) tempRequest.splice(idxLR, 1);
        }
        // *: Check if Group is not SCEMD or County Group
        if (notWithInSCEMDCounty) {
          const idxRI = tempInActive.findIndex((a) => a.code === ProgramCodes.REP);
          const idxRR = tempRequest.findIndex((a) => a.code === ProgramCodes.REP);
          if (idxRI > -1) tempInActive.splice(idxRI, 1);
          if (idxRR > -1) tempRequest.splice(idxRR, 1);
        }
        if (userPermission !== AccessPermission.SUPERADMIN) {
          // *: Do now show DECON to non-super Admin
          const idxDI = tempInActive.findIndex((a) => a.code === ProgramCodes.DECON);
          const idxDR = tempRequest.findIndex((a) => a.code === ProgramCodes.DECON);
          if (idxDI > -1) tempInActive.splice(idxDI, 1);
          if (idxDR > -1) tempRequest.splice(idxDR, 1);
        }

        setActiveSites(tempActive);
        setInActiveSites(tempInActive);
        setSiteRequests(tempRequest);
      }
    }
  }, [userRecord]);

  // User Group Admin Verify
  useEffect(() => {
    if (userRecord && userRecord.currentGroups) {
      const selectedUserGroupInfo = userRecord.currentGroups;
      const idxGroupInfo = selectedUserGroupInfo.findIndex((g) => g.i === Number(groupId));
      if (idxGroupInfo > -1) {
        const groupInfo = selectedUserGroupInfo[idxGroupInfo];
        setActiveGroupInfo(groupInfo);
        if (groupInfo && groupInfo.a) setIsGroupAdmin(true);
      } else {
        setIsGroupAdmin(false);
      }
    }
  }, [userRecord]);

  useEffect(() => {
    if (accessPermission && userActiveGroupID) {
      if (accessPermission === AccessPermission.SUPERADMIN) {
        const filteredGroup = groupLists.filter(
          (g) => g.pvParentGroupID === 0 || g.pvParentGroupID === null
        );
        return setFilteredGroupLists(() => filteredGroup);
      }

      if (accessPermission === AccessPermission.GROUPADMIN) {
        const filteredGroup = groupLists.filter(
          (g) => g.pvGroupID === userActiveGroupID || g.pvParentGroupID === userActiveGroupID
        );
        return setFilteredGroupLists(() => filteredGroup);
      }

      if (accessPermission === AccessPermission.SUBGROUPADMIN) {
        const filteredGroup = groupLists.filter((g) => g.pvGroupID === userActiveGroupID);
        setFilteredGroupLists(() => filteredGroup);
      }
    }
  }, [accessPermission, userActiveGroupID, groupLists]);

  const handleChangeTab = (_event: React.SyntheticEvent, tab: TabName) => {
    if (tab === 'user' && tabName !== 'user') {
      dispatch(changeTab({ tabName: tab }));
      navigate(`/user/view/${userId}`);
    }
  };

  const navigateBack = () => {
    if (propsState && propsState.userId) {
      return navigate(`/user/view/${propsState.userId}`);
    }
    return navigate(-1);
  };

  const onSubmit: SubmitHandler<UserPositionsFormData> = async (data) => {
    if (userId && activeGroupInfo) {
      setInProcessing(true);
      const payload: IPositionsPayload = {
        pvAccountID: Number(userId),
        pvPositionID: data.positions.filter((v) => v.checked).map((p) => p.value),
        pvGroupName: activeGroupInfo.n,
        forApproval: isUserPendingApproval ?? false,
      };

      await dispatch(updateUserToPositions(payload))
        .unwrap()
        .then(async () => {
          await refreshUserInfo();
        })
        .finally(() => {
          setInProcessing(false);
          setOpenEditPositions(false);
        });
    }
  };

  const handleModalRevokeGroup = async (confirm: boolean) => {
    if (confirm) {
      setIsRevokeGroupProcessing(true);
      if (userId && groupId) {
        const currentPositions = positionsForm.getValues().positions;
        const payload: IRevokeGroupPayload = {
          userId: Number(userId),
          groupId: Number(groupId),
          positions: currentPositions.filter((v) => v.checked).map((v) => v.value),
        };

        await dispatch(deleteUserToGroup(payload))
          .unwrap()
          .then(async () => {
            await refreshUserInfo();
          })
          .finally(() => {
            setIsRevokeGroupProcessing(false);
            setOpenRevokeGroup(false);
            navigate(-1);
          });
      }
    } else {
      setOpenRevokeGroup(false);
    }
  };

  const toggleGroupAdmin = async () => {
    if (userId && groupId) {
      setProcessingGroupAdmin(true);
      const payload: AssignGroupAdminPayload = {
        userId: Number(userId),
        groupId: Number(groupId),
        isGroupAdmin: !isGroupAdmin ? 1 : 0,
      };

      await dispatch(setGroupAdmin(payload))
        .unwrap()
        .then(async () => {
          setIsGroupAdmin((prev) => !prev);
          await refreshUserInfo();
        })
        .catch((err) => {
          console.log('err >>>', err);
        })
        .finally(() => {
          setProcessingGroupAdmin(false);
        });
    }
  };

  async function refreshUserInfo() {
    await dispatch(fetchSelectedUser(Number(userId)))
      .unwrap()
      .then((resp) => {
        if (resp && resp?.account2position) {
          const dataPermissions: IPermissionList[] = generateDataPermissions(
            resp,
            userPermission,
            userActiveGroupID,
            groupLists,
            currentUserGroupInfo,
            loggedInUserGroupInfo
          );
          if (dataPermissions.length) {
            dispatch(updateUserPermission(dataPermissions));
          }
        }
      })
      .catch((err) => {
        console.log('err >>>', err);
        throw err;
      });
  }

  const handleModalRevokeAccess = (appCode: ProgramCodes | string, appTitle: string) => {
    if (appCode) {
      grantAccessForm.setValue('programCode', appCode);
      setMessageModal(
        `You are about to revoke ${fullName} access to ${appTitle}. Do you want to continue?`
      );
      setOpenRevokeAccess(true);
    }
  };

  const confirmRevokeAccess = async (confirm: boolean) => {
    if (confirm) {
      const { programCode, attributes, groupId } = grantAccessForm.getValues();
      const siteName = ProgramTitles[programCode as ProgramCodes];
      if (attributes && programCode) {
        const indexOfA = findKeyIndAttribute(attributes, programCode);
        setInProcessAttribute(true);
        const newAttribute = SetNewAttributesData(
          attributes,
          indexOfA,
          programCode,
          SiteStatusCodes.DENY,
          groupId,
          false,
          null,
          true
        );

        const payload: PayloadAttribute = {
          id: Number(userId),
          groupId: Number(groupId),
          attributes: { attributes: newAttribute },
          isRequest: false,
          isDeny: false,
          isGrant: false,
          info: {
            site: siteName,
            email: userEmail,
            name: fullName,
          },
        };

        const resultUpdate = await execUpdateAttribute(payload);
        if (resultUpdate) {
          await refreshUserInfo();
        }
      }
      setInProcessAttribute(false);
      setOpenRevokeAccess(false);
    } else {
      setOpenRevokeAccess(false);
    }
    grantAccessForm.setValue('programCode', null);
  };

  const handleModalDenyGrantSiteRequest = (
    appCode: ProgramCodes | string,
    appTitle: string,
    action: SiteAction
  ) => {
    const idxOfPermission = findKeyIndAttribute(activePermissionCodes, appCode);
    if (action === SiteAction.UPDATE) {
      grantAccessForm.setValue('isUpdate', true);
    }

    if (appCode) {
      grantAccessForm.setValue('programCode', appCode);
      if (
        (appCode === ProgramCodes.CDOWN || appCode === ProgramCodes.VEOC) &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', null);
        grantAccessForm.setValue('selections', defaultSelections);
        setOpenGrantSiteAccess(true);
      } else if (
        appCode === ProgramCodes.LEMPG &&
        Number(groupId) === 1 &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', 'AC');
        grantAccessForm.setValue('selections', LEMPGSelectOptions);
        setOpenGrantSiteAccess(true);
      } else if (
        appCode === ProgramCodes.SUPPTL &&
        Number(groupId) === 1 &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', 'AC');
        grantAccessForm.setValue('selections', LEMPGSelectOptions);
        setOpenGrantSiteAccess(true);
      } else if (
        appCode === ProgramCodes.MITG &&
        Number(groupId) === 1 &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', null);
        grantAccessForm.setValue('selections', MitigationSelectOptions);
        setOpenGrantSiteAccess(true);
      } else if (
        appCode === ProgramCodes.MITG &&
        Number(groupId) !== 1 &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', 'G');
        setMessageModal(`You are about to grant ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
        setOpenGrantSiteRequest(true);
      } else if (
        appCode === ProgramCodes.REP &&
        Number(groupId) === 1 &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', null);
        grantAccessForm.setValue('selections', REPSelectOptions);
        setOpenGrantSiteAccess(true);
      } else if (
        appCode === ProgramCodes.REP &&
        Number(groupId) !== 1 &&
        action === SiteAction.GRANT
      ) {
        grantAccessForm.setValue('permissionType', 'G');
        setMessageModal(`You are about to grant ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
        setOpenGrantSiteRequest(true);
      } else if (appCode === ProgramCodes.REP && action === SiteAction.UPDATE) {
        if (idxOfPermission > -1) {
          const permissionCode = activePermissionCodes[idxOfPermission][appCode];
          const REPOptions = REPSelectOptions;
          const isPermissionExist = REPOptions.findIndex((v) => v.value === permissionCode) > -1;
          if (permissionCode === 'G' && !isPermissionExist) {
            REPOptions.push({
              title: 'Group',
              value: 'G',
            });
          }
          if (permissionCode === 'G') {
            const REPOption = [
              {
                title: 'Group',
                value: 'G',
              },
            ];
            grantAccessForm.setValue('permissionType', permissionCode);
            grantAccessForm.setValue('selections', REPOption);
            setOpenGrantSiteAccess(true);
          } else {
            grantAccessForm.setValue('permissionType', permissionCode);
            grantAccessForm.setValue('selections', REPOptions);
            grantAccessForm.setValue('isUpdate', true);
            setOpenGrantSiteAccess(true);
          }
        } else {
          if (Number(groupId) === 1) {
            grantAccessForm.setValue('permissionType', null);
            grantAccessForm.setValue('selections', REPSelectOptions);
            setOpenGrantSiteAccess(true);
          } else {
            grantAccessForm.setValue('permissionType', 'G');
            setMessageModal(`You are about to grant ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
            setOpenGrantSiteRequest(true);
          }
        }
      } else if (
        (appCode === ProgramCodes.CDOWN || appCode === ProgramCodes.VEOC) &&
        action === SiteAction.UPDATE
      ) {
        if (idxOfPermission > -1) {
          const permissionCode = activePermissionCodes[idxOfPermission][appCode];
          grantAccessForm.setValue('permissionType', permissionCode);
          grantAccessForm.setValue('selections', defaultSelections);
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        } else {
          grantAccessForm.setValue('permissionType', null);
          grantAccessForm.setValue('selections', defaultSelections);
          setOpenGrantSiteAccess(true);
        }
      } else if (
        (appCode === ProgramCodes.LEMPG || appCode === ProgramCodes.SUPPTL) &&
        action === SiteAction.UPDATE
      ) {
        if (idxOfPermission > -1) {
          const permissionCode = activePermissionCodes[idxOfPermission][appCode];
          grantAccessForm.setValue('permissionType', permissionCode);
          grantAccessForm.setValue('selections', LEMPGSelectOptions);
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        } else if (Number(groupId) === 1) {
          grantAccessForm.setValue('permissionType', 'AC');
          grantAccessForm.setValue('selections', LEMPGSelectOptions);
          setOpenGrantSiteAccess(true);
        } else {
          grantAccessForm.setValue('permissionType', 'G');
          setMessageModal(`You are about to grant ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
          setOpenGrantSiteRequest(true);
        }
      } else if (appCode === ProgramCodes.MITG && action === SiteAction.UPDATE) {
        if (idxOfPermission > -1) {
          const permissionCode = activePermissionCodes[idxOfPermission][appCode];
          const SelectOptions = MitigationSelectOptions;
          const isPermissionExist = SelectOptions.findIndex((v) => v.value === permissionCode) > -1;
          if (permissionCode === 'G' && !isPermissionExist) {
            SelectOptions.push({
              title: 'Group',
              value: 'G',
            });
          }
          grantAccessForm.setValue('permissionType', permissionCode);
          grantAccessForm.setValue('selections', SelectOptions);
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        } else {
          if (Number(groupId) !== 1) {
            grantAccessForm.setValue('permissionType', 'G');
            setMessageModal(`You are about to grant ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
            setOpenGrantSiteRequest(true);
          }
          if (Number(groupId) === 1) {
            grantAccessForm.setValue('permissionType', null);
            grantAccessForm.setValue('selections', MitigationSelectOptions);
            setOpenGrantSiteAccess(true);
          }
        }
      } else if (appCode === ProgramCodes.DMA && action === SiteAction.GRANT) {
        grantAccessForm.setValue('permissionType', null);
        if (Number(groupId) === 1) {
          grantAccessForm.setValue('selections', DMASelectOptions);
        } else {
          const filteredDMASelectOptions = DMASelectOptions.filter((v) => v.value !== 'L');
          grantAccessForm.setValue('selections', filteredDMASelectOptions);
        }
        setOpenGrantSiteAccess(true);
      } else if (appCode === ProgramCodes.DMA && action === SiteAction.UPDATE) {
        if (idxOfPermission > -1) {
          const permissionCode = activePermissionCodes[idxOfPermission][appCode];
          grantAccessForm.setValue('permissionType', permissionCode);
          if (Number(groupId) === 1) {
            grantAccessForm.setValue('selections', DMASelectOptions);
          } else {
            const filteredDMASelectOptions = DMASelectOptions.filter((v) => v.value !== 'L');
            grantAccessForm.setValue('selections', filteredDMASelectOptions);
          }
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        } else {
          grantAccessForm.setValue('permissionType', null);
          if (Number(groupId) === 1) {
            grantAccessForm.setValue('selections', DMASelectOptions);
          } else {
            const filteredDMASelectOptions = DMASelectOptions.filter((v) => v.value !== 'L');
            grantAccessForm.setValue('selections', filteredDMASelectOptions);
          }
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        }
      } else if (appCode === ProgramCodes.DECON && action === SiteAction.GRANT) {
        grantAccessForm.setValue('permissionType', defaultSelections[1].value);
        grantAccessForm.setValue('selections', defaultSelections);
        setOpenGrantSiteAccess(true);
      } else if (appCode === ProgramCodes.DECON && action === SiteAction.UPDATE) {
        if (idxOfPermission > -1) {
          const permissionCode = activePermissionCodes[idxOfPermission][appCode];
          grantAccessForm.setValue('permissionType', permissionCode);
          grantAccessForm.setValue('selections', defaultSelections);
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        } else {
          grantAccessForm.setValue('permissionType', null);
          grantAccessForm.setValue('selections', defaultSelections);
          grantAccessForm.setValue('isUpdate', true);
          setOpenGrantSiteAccess(true);
        }
      } else {
        if (action === SiteAction.DENY) {
          setMessageModal(`You are about to deny ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
          setOpenDenySiteRequest(true);
        }

        if (action === SiteAction.GRANT || action === SiteAction.UPDATE) {
          grantAccessForm.setValue('permissionType', 'G');
          setMessageModal(`You are about to grant ${fullName} access to ${appTitle}.
            Are you sure you want to do this?`);
          setOpenGrantSiteRequest(true);
        }
      }
    }
  };

  const execUpdateAttribute = async (payload: PayloadAttribute) => {
    try {
      const result = await dispatch(updateUserAttribute(payload)).unwrap();
      grantAccessForm.setValue('isUpdate', false);
      return result !== undefined;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onSubmitDenyGrantAccess = async (confirm: boolean, action: SiteAction) => {
    if (confirm) {
      const { attributes, programCode, groupId, permissionType, isUpdate } =
        grantAccessForm.getValues();
      const siteName = ProgramTitles[programCode as ProgramCodes];
      if (attributes && programCode) {
        const indexOfA = findKeyIndAttribute(attributes, programCode);
        setInProcessAttribute(true);
        if (action === SiteAction.DENY) {
          const newAttribute = SetNewAttributesData(
            attributes,
            indexOfA,
            programCode,
            SiteStatusCodes.DENY,
            groupId,
            false
          );

          const payload: PayloadAttribute = {
            id: Number(userId),
            groupId: Number(groupId),
            attributes: { attributes: newAttribute },
            isRequest: false,
            isDeny: true,
            isGrant: false,
            info: {
              site: siteName,
              email: userEmail,
              name: fullName,
            },
          };

          const resultUpdate = await execUpdateAttribute(payload);
          if (resultUpdate) {
            await refreshUserInfo();
          }
          setInProcessAttribute(false);
          setOpenDenySiteRequest(false);
        }

        if (action === SiteAction.GRANT) {
          const newAttribute = SetNewAttributesData(
            attributes,
            indexOfA,
            programCode,
            SiteStatusCodes.ACTIVE,
            groupId,
            true,
            permissionType,
            false,
            isUpdate
          );

          const payload: PayloadAttribute = {
            id: Number(userId),
            groupId: Number(groupId),
            attributes: { attributes: newAttribute },
            isRequest: false,
            isDeny: false,
            isGrant: !isUpdate,
            info: {
              site: siteName,
              email: userEmail,
              name: fullName,
            },
          };

          const resultUpdate = await execUpdateAttribute(payload);
          if (resultUpdate) {
            await refreshUserInfo();
          }
          setInProcessAttribute(false);
          setOpenGrantSiteRequest(false);
        }
        grantAccessForm.setValue('programCode', null);
      }
    } else {
      grantAccessForm.setValue('programCode', null);
      if (action === SiteAction.DENY) {
        setOpenDenySiteRequest(false);
      }

      if (action === SiteAction.GRANT) {
        setOpenGrantSiteRequest(false);
      }
    }
  };

  const onSubmitOptionsGrantAccess = async (confirm: boolean) => {
    if (confirm) {
      const { attributes, programCode, permissionType, groupId, isUpdate } =
        grantAccessForm.getValues();
      const siteName = ProgramTitles[programCode as ProgramCodes];
      if (attributes && programCode) {
        setInProcessAttribute(true);
        const indexOfA = findKeyIndAttribute(attributes, programCode);
        const newAttribute = SetNewAttributesData(
          attributes,
          indexOfA,
          programCode,
          SiteStatusCodes.ACTIVE,
          groupId,
          true,
          permissionType,
          false,
          isUpdate
        );

        const payload: PayloadAttribute = {
          id: Number(userId),
          groupId: Number(groupId),
          attributes: { attributes: newAttribute },
          isRequest: false,
          isDeny: false,
          isGrant: !isUpdate, // *: Only send notification for new grant access
          info: {
            site: siteName,
            email: userEmail,
            name: fullName,
          },
        };

        const resultUpdate = await execUpdateAttribute(payload);
        if (resultUpdate) {
          await refreshUserInfo();
        }
        setInProcessAttribute(false);
        setOpenGrantSiteAccess(false);
      }
      setOpenGrantSiteAccess(false);
    } else {
      setOpenGrantSiteAccess(false);
    }
  };

  const handleModalGroup = () => {
    setOpenAssignGroup(!openAssignGroup);
    setStep(AssignGroupFormStep.GROUP);
    const formData: UserGroupFormData = {
      step: AssignGroupFormStep.GROUP,
      groupId: 0,
      positions: [],
      isGroupAdmin: false,
      userId: Number(userId) || 0,
    };
    groupForm.reset(formData);
  };

  const handleChangeStep = (next: AssignGroupFormStep) => {
    const nextIndex = findIndex(stepHistory, { step: next });
    const prevIndex = findIndex(stepHistory, { step });
    const prevStep = stepHistory[prevIndex];
    const steps = [...stepHistory];
    if (nextIndex > prevIndex && !prevStep.complete) {
      steps[prevIndex] = { ...prevStep, complete: true };
    }
    steps[nextIndex] = { ...steps[nextIndex], active: true };
    setStepHistory(steps);
    setStep(next);
    groupForm.setValue('step', next);
  };

  const onSubmitAddGroup = async () => {
    try {
      const data = groupForm.getValues();
      const filterPositions = data.positions.filter((p) => p.checked).map((p) => p.value);
      const payload: IAssignGroupPayload = {
        userId: data.userId,
        groupId: data.groupId,
        positions: filterPositions,
        isGroupAdmin: data.isGroupAdmin ? 1 : 0,
      };
      const resultAdd = await dispatch(addUserToGroup(payload))
        .unwrap()
        .then((resp) => resp);
      if (resultAdd) {
        await dispatch(fetchSelectedUser(data.userId))
          .unwrap()
          .then((resp) => {
            if (resp && resp?.account2position) {
              const dataPermissions: IPermissionList[] = generateDataPermissions(
                resp,
                accessPermission,
                userActiveGroupID,
                groupLists,
                currentUserGroupInfo,
                loggedInUserGroupInfo
              );
              if (dataPermissions.length) {
                dispatch(updateUserPermission(dataPermissions));
              }
            }
          });
        handleModalGroup();
        setTimeout(() => {
          if (propsState && propsState.userId) {
            return navigate(`/user/view/${propsState.userId}`);
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const combineProps: UserPermissionViewProps = {
    groupLists: filteredGroupLists,
    step,
    stepHistory,
    userPermission,
    groupForm,
    positionsForm,
    grantAccessForm,
    activeSites,
    inActiveSites,
    siteRequests,
    singleGroupUser,
    isGroupAdmin,
    processingGroupAdmin,
    isPositionLoading,
    inProcessing,
    isProcessing,
    fetchingUsers,
    isRevokeGroupProcessing,
    groupPosition,
    tabName,
    openAssignGroup,
    openGrantSiteAccess,
    openGrantSiteRequest,
    openDenySiteRequest,
    openRevokeAccess,
    openRevokeGroup,
    openEditPositions,
    fullName,
    messageModal,
    inProcessAttribute,
    hasNoPositions,
    hasNoPositionsGroupForm,
    onSubmit,
    handleChangeTab,
    handleChangeStep,
    handleModalGroup,
    navigateBack,
    handleModalDenyGrantSiteRequest,
    handleModalRevokeAccess,
    handleModalRevokeGroup,
    handleOpenModalRevokeGroup: () => setOpenRevokeGroup(!openRevokeGroup),
    handleModalEditPositions: () => setOpenEditPositions(!openEditPositions),
    confirmRevokeAccess,
    onSubmitDenyGrantAccess,
    onSubmitOptionsGrantAccess,
    toggleGroupAdmin,
    onSubmitAddGroup,
  };

  return <UserPermissionView {...combineProps} />;
};

export default UserPermissionViewMain;
